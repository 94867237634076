<template>
  <div>
  </div>
</template>

<script>

export default {
  data: () => {
    return {}
  },
  computed: {
  },
  methods: {
    getSourceTypeStyle(view, sourceType) {
      if (sourceType == null) return null
      view.style = view.style ?? {}
      view.style.sourceType = view.style.sourceType ?? {}
      view.style.sourceType[sourceType] = view.style.sourceType[sourceType] ?? {}
      return view.style.sourceType[sourceType]
    },
    getSourceTypeConfig(sourceType) {
      if (sourceType == null) return null
      const labels = this.$store.getters["config/labels"]
      return labels.views.datasets.types[sourceType]
    },
    getDimensionName(sourceType) {
      return this.getSourceTypeConfig(sourceType)?.dimension
    },
    getDimension(sourceType) {
      if (sourceType == null) return null
      const labels = this.$store.getters["config/labels"]
      const sourceObj = labels.views.datasets.types[sourceType]
      return labels.views.datasets.dimensions[sourceObj?.dimension]
    },
    stackingSelected(view, sourceType) {
      const style = this.getSourceTypeStyle(view, sourceType)
      return style?.stacked
    },
    toggleStacking(view, sourceType) {
      let ax = this.getSourceTypeStyle(view, sourceType)
      ax.stacked = !ax.stacked
    },
    logAxisSelected(view, sourceType) {
      const style = this.getSourceTypeStyle(view, sourceType)
      return style?.log
    },
    toggleLogAxis(view, sourceType) {
      let style = this.getSourceTypeStyle(view, sourceType)
      style.log = !style.log
    },
    relativeAxisSelected(view, sourceType) {
      const style = this.getSourceTypeStyle(view, sourceType)
      return style?.relative
    },
    toggleRelativeAxis(view, sourceType) {
      let ax = this.getSourceTypeStyle(view, sourceType)
      ax.relative = !ax.relative
    },
    stackingAllowed(sourceType) {
      return this.getDimension(sourceType)?.allowStacking
    },
    logAxisAllowed(sourceType) {
      return this.getDimension(sourceType)?.allowLogAxis
    },
    relativeAxisAllowed(sourceType) {
      return this.getDimension(sourceType)?.allowRelativeAxis
    },
    anyDrawingOptionEnabled(sourceType) {
      const dim = this.getDimension(sourceType)
      if (dim == null) return false
      return dim.allowStacking || dim.allowLogAxis || dim.allowRelativeAxis
    },
    isPlotVisible(dataset, groupItem) {
      if (dataset?.grouping)
        return !dataset?.style?.invisible_grp?.[`${dataset.grouping}_${groupItem}`]
      return !dataset?.style?.invisible
    },
    setPlotVisibility(dataset, groupItem, new_visibility) {
      if (dataset.grouping) {
        let ig = dataset.style.invisible_grp || {}
        ig[`${dataset.grouping}_${groupItem}`] = !new_visibility
        dataset.style.invisible_grp = ig
      } else {
        dataset.style.invisible = !new_visibility
      }
    },
    togglePlotVisibility(dataset, groupItem) {
      const isVisible = this.isPlotVisible(dataset, groupItem)
      this.setPlotVisibility(dataset, groupItem, !isVisible)
    },
    isDatasetFullyVisible(dataset) {
      if (dataset.grouping) {
        return dataset.chartData.legend.every(
          (legend) => { return this.isPlotVisible(dataset, legend.label)}
        )
      }
      return this.isPlotVisible(dataset, null)
    },
    isDatasetFullyHidden(dataset) {
      // warning: this is not the opposite of isDatasetFullyVisible!
      // isDatasetHidden returns true if all plots are hidden, false if any are visible
      if (dataset.grouping) {
        return dataset.chartData.legend.every(
          (legend) => { return !this.isPlotVisible(dataset, legend.label)}
        )
      }
      return !this.isPlotVisible(dataset, null)
    },
    setDatasetVisibility(dataset, new_visibility) {
      if (dataset.grouping) {
        dataset.chartData.legend.forEach((legend) => {
          this.setPlotVisibility(dataset, legend.label, new_visibility)
        })
      } else {
        this.setPlotVisibility(dataset, null, new_visibility)
      }
    }
  },
}
</script>

<style scoped>
</style>
