<template>
  <div class="tab-view">
    <div class="tab-view-tabs">
      <draggable v-model="titleIndices" group="tab-view-titles" tag="ul"
        @start="drag=true" @end="drag=false" item-key="id" :animation="300"
        :disabled="!draggingEnabled">
        <template #item="{element:index}">
          <li
            :class="(selectedTabIndex == index) ? 'active' : ''"
            @click="$emit('tab:index', index)"
          >
            {{ titles[index] }}
          </li>
        </template>
      </draggable>
    </div>
    <div class="tab-view-content">
      <slot></slot>
    </div>
  </div>
</template>


<script>
import draggable from 'vuedraggable'

export default {
  name: 'TabView',
  components: {
    draggable,
  },
  props: {
    titles: {
      type: Array
    },
    selectedTabIndex: {
      type: Number,
      default: 0
    },
    draggingEnabled: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    titleIndices: {
      get() {
        return Array.from(this.titles.keys())
      },
      set(value) {
        this.$emit("dragdrop:orderChanged", value)
      },
    },
  }
}
</script>

<style scoped>
.tab-view-content {
  background: #fff;
  border-radius: 7px;
  border-left: 1px solid var(--c-bright-grey);
  border-right: 1px solid var(--c-bright-grey);
  border-bottom: 1px solid var(--c-bright-grey);
}

.widget-container.nopadding {
  padding: 0;
}
.tab-view-tabs {
  width: 100%;
}
.tab-view-tabs ul {
  display: flex;
  flex-direction: row;
  list-style-type: none;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
}
.tab-view-tabs ul li {
  max-width: 320px;
  height: 44px;
  flex-grow: 1;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  border-top: 1px solid #E6EBF7;
  border-left: 1px solid #E6EBF7;
  border-right: 1px solid #E6EBF7;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-weight: 400;
  cursor: pointer;
  background-color: rgba(237,241,250, 0.5);
  color: #7882A8;
  margin-right: 8px;
}
.tab-view-tabs ul li.active {
  background-color: #fff;
}

</style>

<style>
.widget-container > div {
  flex-grow: 1;
}
.widget-background-blue {
    background-color: var(--c-button-blue);
}
</style>
