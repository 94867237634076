<template>
  <div class="reports-sidebar">
    <SidebarContent>
        <template v-slot:content>
            <h2 class="underline left">
                {{ viewTitle }}
            </h2>
            <div class="scrollable-container sidebar">
              <div class="dataset-top-actions">
                <h4>Datasets</h4>
                <button class="button plain add-parameter" @click="onAddDataset">
                  <inline-svg class="add-icon" :src="require('@/assets/svg/icons/reports/icon-add-medium.svg')" width="12" height="12"></inline-svg>
                </button>
              </div>

              <div class="dataset-tiles">
                <draggable v-model="datasets" group="right-datasets" @start="drag=true" @end="drag=false" item-key="id" :animation="300">
                  <template #item="{element:dataset, index}">
                    <DatasetTile
                      @click="onClickDataset(index)"
                      v-bind:key="index"
                      :dataset="dataset"
                      :view="view"
                      @yaxis:toggleLogAxis="onToggleLogAxis(dataset, view)"
                      @yaxis:toggleRelativeAxis="onToggleRelativeAxis(dataset, view)"
                      @yaxis:toggleStack="onToggleStack(dataset, view)"
                      @visibility:toggle="onToggleVisibility(dataset)"
                      @dataset:duplicate="onDuplicateDataset(index)"
                      @dataset:delete="onDeleteDataset(index)">
                    </DatasetTile>
                  </template>
                </draggable>
              </div>

            </div>
        </template>
    </SidebarContent>
    <ConfirmDialog v-if="datasetIndexToBeDeleted > -1" title="Delete dataset?">
      <template v-slot:message><span v-html="deleteDatasetConfirmMessage"></span></template>
      <template v-slot:actions>
        <div class="button-group">
          <button @click.stop="doCancelDelete" class="button ghost mediumsize rounded">Cancel</button>
          <button @click.stop="doDeleteDataset" class="button primary mediumsize rounded">Delete</button>
        </div>
      </template>
    </ConfirmDialog>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import SidebarContent from "./SidebarContent.vue"
import DatasetTile from "@/components/reports/DatasetTile.vue"
import ConfirmDialog from "@/components/ui/ConfirmDialog.vue"
import useUI from '@/js/useUI.js'
import ReportPlotMixin from "@/components/mixins/ReportPlotMixin.vue"

export default {
  name: 'ReportSidebar',
  mixins: [ReportPlotMixin],
  components: {
      SidebarContent,
      DatasetTile,
      ConfirmDialog,
      draggable,
  },
  data: function() {
    return {
      datasetIndexToBeDeleted: -1,
    }
  },
  computed: {
    report() {
      return this.$store.getters["reports/currentReport"]
    },
    view() {
      return this.$store.getters["reports/currentView"]
    },
    viewIndex() {
      return this.$store.getters["reports/currentViewIndex"]
    },
    viewTitle() {
      return this.view?.title || "Untitled"
    },
    datasets: {
      get() {
        return this.view?.datasets || []
      },
      set(value) {
        this.$store.dispatch('reports/setDatasets', {
          report: this.report,
          viewIndex: this.viewIndex,
          datasets: value,
        })
      },
    },
    deleteDatasetConfirmMessage() {
      return `Do you really want to delete this dataset?`
    },
  },
  methods: {
    onAddDataset() {
      this.onClickDataset(this.report.views[this.viewIndex].datasets.length)
      this.$store.dispatch("reports/addNewDataset", {
        viewIndex: this.viewIndex,
        report: this.report
      }).then( () => {

      })
    },
    onDuplicateDataset(datasetIndex) {
      this.$store.dispatch("reports/duplicateDataset", {
        viewIndex: this.viewIndex,
        datasetIndex: datasetIndex,
        report: this.report
      })
    },
    onDeleteDataset(datasetIndex) {
      useUI(this.$store).blockUI({ complete: true })
      this.datasetIndexToBeDeleted = datasetIndex
    },
    doDeleteDataset() {
      this.$store.dispatch("reports/deleteDataset", {
        viewIndex: this.viewIndex,
        datasetIndex: this.datasetIndexToBeDeleted,
        report: this.report
      }).then( () => {
        useUI(this.$store).unblockUI()
      }).finally( () => {
        this.datasetIndexToBeDeleted = -1
      })
    },
    onClickDataset(index) {
      let reportId = this.$route.params.reportId
      reportId = "current"
      this.$router.push(`/reports/${reportId}/views/${this.viewIndex}/datasets/${index}/filters`)
    },
    onToggleVisibility(dataset) {
      this.setDatasetVisibility(dataset, !this.isDatasetFullyVisible(dataset))
      this.report.transient = true
    },
    onToggleLogAxis(dataset, view) {
      this.toggleLogAxis(view, dataset?.sourceType)
      this.report.transient = true
    },
    onToggleRelativeAxis(dataset, view) {
      this.toggleRelativeAxis(view, dataset?.sourceType)
      this.report.transient = true
    },
    onToggleStack(dataset, view) {
      this.toggleStacking(view, dataset?.sourceType)
      this.report.transient = true
    },
    doCancelDelete() {
      this.datasetIndexToBeDeleted = -1
      useUI(this.$store).unblockUI()
    }
  },
}
</script>

<style scoped>
.reports-sidebar {
  display: flex;
  flex-direction: column;
  align-content: space-between;
  height: 100%;
}
.dataset-tiles {
  width: 100%;
}
.dataset-top-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.dataset-top-actions h4 {
  display: inline-block;
  flex-grow: 1;
  text-align: left;
}

h4 {
  font-weight: 400;
}
button.add-parameter {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0;
  margin: 0;
  color: var(--c-dark-blue);
}
button.cancel {
  text-transform: uppercase;
  color: var(--c-greyish);
}
:deep(.sidebar-actions) {
  padding: 0 20px 0 0;
}
</style>
