<template>
  <div class="mediaplan-comparision">
    <div class="content">
      <table v-if="channels">
          <th width="5%"></th>
          <th width="17%">Media channel</th>
          <th width="17%">Budget</th>
          <th width="17%">Budget difference</th>
          <th width="17%">Sell-out uplift</th>
          <th width="17%">Sell-out uplift difference</th>
          <th width="10%">ROMI</th>
          <tr v-for="(channel, index) in channels" v-bind:key="index" @click="onSelectChannel(channel)">
            <td><CheckBox class="checkbox"
                :class="selectedChannels.includes(channel.key) ? 'always-visible' : ''"
                :checked="selectedChannels.includes(channel.key)" /></td>
            <td>{{ channel.name }}</td>
            <td>
              <span class="color-a" v-if="channel.a">A {{ channel.a.budget }}</span><br>
              <span class="color-b" v-if="channel.b">B {{ channel.b.budget }}</span>
            </td>
            <td>{{ channel.diffBudget.text }}
                <InfoHud v-if="!channel.diffBudget.available">
                    <span v-if="channel.a && channel.b">Different time periods</span>
                    <span v-else>No comparison selected</span>
                </InfoHud>
            </td>
            <td>
              <span class="color-a" v-if="channel.a">A {{ channel.a.sellOutUplift }}</span><br>
              <span class="color-b" v-if="channel.b">B {{ channel.b.sellOutUplift }}</span>
            </td>
            <td>{{ channel.diffSellOutUplift.text }}
                <InfoHud v-if="!channel.diffSellOutUplift.available">
                  <slot>
                    <span v-if="channel.a && channel.b">Different time periods</span>
                    <span v-else>No comparison selected</span>
                  </slot>
                </InfoHud>
            </td>
            <td>
              <span class="color-a" v-if="channel.a">A {{ channel.a.romi }}</span><br>
              <span class="color-b" v-if="channel.b">B {{ channel.b.romi }}</span>
            </td>
          </tr>
      </table>
    </div>
  </div>
</template>

<script>
import UiMixin from "@/components/mixins/UiMixin.vue";
import CheckBox from "@/components/ui/CheckBox.vue";
import useUI from '@/js/useUI.js'
import InfoHud from "@/components/ui/InfoHud.vue"

export default {
  name: "MediaplanComparison",
  mixins: [UiMixin],
  components: {
    CheckBox,
    InfoHud
  },
  props: {
    probeFinancialData: {
      type: Object
    },
    referenceFinancialData: {
      type: Object
    },
  },
  methods: {
    onPredictionClick(mediaplan) {
      this.$store.commit("mediaplanning/currentMediaplanId", mediaplan.id);
      this.$router.push("/predictions");
      useUI(this.$store).blockUI( { unblockDisabled: true });
    },
    onSelectChannel(channel) {
      this.$store.commit("analysis/toggleSelectedChannel", channel.key)
    }
  },
  computed: {
    selectedChannels() {
      return this.$store.state.analysis.selectedChannels
    },
    isPeriodsAreEqual() {
      let weeks = this.$store.state.config.weeks
      let mediaplanA = this.referenceFinancialData.mediaplan
      let mediaplanB = this.probeFinancialData.mediaplan
      if (mediaplanA && mediaplanB) {
        let valuesA = mediaplanA?.channels?.[0]?.values || [];
        let valuesB = mediaplanB?.channels?.[0]?.values || [];
        for (var i=0; i<weeks.length; i++) {
          if (!((valuesA[i]?.week_index == valuesB[i]?.week_index) &&
                (valuesA[i]?.year == valuesB[i]?.year)))
          {
            return false
          }
        }
        return true
      }
      return false
    },
    channels() {
      var channel, channelA, channelB, diffBudget, diffPercentage, channels = [], channelKeys = []
      let mediaplanA = this.referenceFinancialData.mediaplan
      let mediaplanB = this.probeFinancialData.mediaplan
      let upliftsA = this.referenceFinancialData.uplifts
      let upliftsB = this.probeFinancialData.uplifts

      // get channel keys from the mediaplan to probe (B)
      // If no probing plan is set, take channel keys from reference mediaplan (A)
      if (mediaplanB) {
        channelKeys = mediaplanB.channels.map( c => c.key )
      } else if (mediaplanA) {
        channelKeys = mediaplanA.channels.map( c => c.key )
      }
      channelKeys.forEach(channelKey => {
        // get channel info or null from plans A and B
        channelA = mediaplanA ? mediaplanA.channels.find( c => c.key == channelKey) : null
        channelB = mediaplanB ? mediaplanB.channels.find( c => c.key == channelKey) : null
        // calculate difference and percentage
        diffBudget = (channelA && channelB) ? channelB.total_value - channelA.total_value : 0
        if (channelA && channelB) {
            diffPercentage = channelA.total_value != 0 ? channelB ? diffBudget / channelA.total_value * 100 : 0 : 0
        }
        var diffBudgetText = "Not available"
        var diffSellOutUpliftText = "Not available"
        if (this.isPeriodsAreEqual) {
          diffBudgetText = diffBudget != undefined ? `${this.formatCurrency(diffBudget)}` : ''
          if (diffPercentage != undefined) {
            diffBudgetText += ` (${diffPercentage.toFixed(2)}%)`
          }
          let upliftA = (upliftsA ? upliftsA[channelKey] : 0)
          let upliftB = upliftsB?.[channelKey]
          let diffSellOutUplift = upliftB - upliftA


          if (!upliftsB || upliftsB[channelKey] == null) {
            diffSellOutUpliftText = `${this.formatCurrency(0)}`
          } else {
            let diffUpliftPercentage = upliftA ? diffSellOutUplift / upliftA * 100 : 0
            diffSellOutUpliftText = `${this.formatNumber(diffSellOutUplift)} (${diffUpliftPercentage.toFixed(2)}%)`
          }

        } else if (channelA && channelB) {
          diffBudgetText = "Not available"
          diffSellOutUpliftText = "Not available"
        } else {
          diffBudgetText = "-"
          diffSellOutUpliftText = "-"
        }
        // setup channel for ui table
        channel = {
          name: channelA?.channel || channelB?.channel,
          key: channelA?.key || channelB?.key,
          diffBudget: {
            available: this.isPeriodsAreEqual,
            text: diffBudgetText
          },
          diffSellOutUplift: {
            available: this.isPeriodsAreEqual,
            text: diffSellOutUpliftText
          },
        }
        // assign reference plan as A and probing plan as B.
        // set null for A or B if the plan does not exist to suppress the display of values in the ui table
        channel['a'] = (channelA && upliftsA) ? {
          budget: `${this.formatCurrency(channelA.total_value)}`,
          sellOutUplift: this.formatCurrency((upliftsA[channelKey] || 0)),
          romi: channelA.total_value > 0
            ? ((upliftsA[channelKey] || 0) / channelA.total_value).toFixed(2)
            : "-",
        } : null
        channel['b'] = (channelB && upliftsB) ? {
            budget: `${this.formatCurrency(channelB.total_value)}`,
            sellOutUplift: this.formatCurrency((upliftsB[channelKey] || 0)),
            romi: channelB.total_value > 0
              ? ((upliftsB[channelKey] || 0) / channelB.total_value).toFixed(2)
              : "-",
        } : null
        channels.push(channel)
      });
      return channels
    },
    contextMenuItems() {
      return [
        {
          title: "Alle ansehen",
          handler: () => {
            this.$router.push("/mediaplans");
          },
        },
      ];
    },
  },
};
</script>

<style scoped>
.mediaplan-comparision {
  /* overflow-x: hidden;
  overflow-y: scroll;
  max-height: 50%; */
}
h3 {
  font-size: 18px;
}
.header {
  text-align: left;
}
table {
  width: 100%;
  border-spacing: 0;
  font-size: 14px;
}
th {
  text-align: left;
  height: 40px;
}
td {
  text-align: left;
  border-bottom: 1px solid var(--c-bright-grey);
}
tr:last-child td {
  border-bottom: none;
}
tr {
  cursor: pointer;
  height: var(--tr-default-height);
}
.checkbox {
  margin-left: 4px;
  margin-top: 2px;
  visibility: hidden;
}
.checkbox.always-visible {
  visibility: visible;
}
tr:hover {
  background-color: var(--c-white-grey);
}
tr:hover .checkbox {
  visibility: visible;
}
.color-a {
  color: var(--c-reference-data);
}
.color-b {
  color: var(--c-probe-data);
}
</style>
